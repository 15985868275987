//
// Custom Example 1 Style
// Here you can put your custom sass code, use global SASS mixings and variables to override the theme or add new styles according to your requirements.
// Most of the changes can be achieved via global or layout level sass variable changes.
//

.my-custom-style {
	padding: 1rem 0.5rem;
	color: kt-brand-color();

	.my-custom-element {
		margin-left: 1rem;
	}
}

@include kt-tablet-and-mobile {
	.my-custom-style {
		padding: 0.5rem 0.25rem;

		.my-custom-element {
			margin-left: 0.5rem;
		}
	}
}

.hover-span-action {
	cursor: pointer;
	color: #8F9BBA;

	img {
		filter: brightness(0) saturate(100%) invert(66%) sepia(5%) saturate(1537%) hue-rotate(186deg) brightness(92%) contrast(87%);
	}

	&:hover {
		color: #2e40d4;

		img {
			filter: invert(19%) sepia(58%) saturate(5031%) hue-rotate(234deg) brightness(88%) contrast(89%);
		}
	}
}

.hover-span-action-remove {
	cursor: pointer;
	color: #8F9BBA;

	img {
		filter: brightness(0) saturate(100%) invert(66%) sepia(5%) saturate(1537%) hue-rotate(186deg) brightness(92%) contrast(87%);
	}

	&:hover {
		color: #dc3545;

		img {
			filter: invert(33%) sepia(66%) saturate(3684%) hue-rotate(335deg) brightness(88%) contrast(94%);
		}
	}
}

.button-add-circle-fill {
	filter: invert(100%) sepia(5%) saturate(21%) hue-rotate(224deg) brightness(106%) contrast(107%);
}

.close-circle-fill {
	filter: brightness(0) saturate(100%) invert(66%) sepia(5%) saturate(1537%) hue-rotate(186deg) brightness(92%) contrast(87%);
}

.hide-border-bottom-header-modal {
	.ant-modal-header {
		border-bottom: 0 !important;
	}
}

.default-color {
	color: #1B2559 !important;
}

.default-black-color {
	color: #1F263E !important;
}

.primary-color {
	color: #338FEB !important;
}

.starDanger:after {
	content: "*";
	color: #dc3545;
	padding-left: 5px;
}

.has-error {
	border-color: #dc3545 !important;
}

.has-error-select {
	border-color: #dc3545 !important;
}

.has-error-single-img {
	width: 106px;
	height: 106px;
	border: 1px dashed #dc3545;
}

.has-error-single-file {
	width: 100px;
	height: 34px;
	border: 1px dashed #dc3545;
}

.has-error-single-file-2 {
	.ant-upload-select-picture-card {
		border: 1px dashed #dc3545 !important;
	}
}

.remove-video {
	position: absolute;
	font-size: 20px;
	color: #ff0000;
	right: 2px;
	background: #fff;
	border-radius: 50%;
	cursor: pointer;
}

.PhoneInputInput {
	border: none;
}

.ant-select.form-control {
	padding: 0px !important;
	height: auto !important;
	min-height: 39px;

	.ant-select-selector {
		height: 100% !important;
		border: none !important;

		.ant-select-selection-search {
			width: 20% !important;
		}
	}
}

.ant-select.form-control.select-user {
	padding: 0px !important;
	height: auto !important;
	min-height: 39px;

	.ant-select-selector {
		height: 100% !important;
		border: none !important;

		.ant-select-selection-search {
			width: 20% !important;
		}
	}
}

.ant-picker-calendar-header .ant-picker-calendar-year-select {
	min-width: 100px;
}

.ant-picker-calendar-header .ant-picker-calendar-month-select {
	min-width: 100px;
	margin-left: 8px;
}

.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
	display: none;
}

.ant-select-multiple {
	.ant-select-selection-item-remove {
		.anticon {
			vertical-align: 0.1em !important;
			margin-left: 3px;
		}
	}
}

.events-training-list {
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		font-size: 12px;
		margin-bottom: 4px;
		color: #fff;
		padding: 4px;
	}

	.offline {
		background: #e86a95;
	}

	.online {
		background: #6ca2f7;
	}
}

.btn-label-default {
	background-color: #eeeded;
	cursor: text !important;
}

.PhoneInputCountryIcon {
	svg {
		margin-top: -8px;
	}
}

dt,
dd {
	display: table-cell;
}

dd {
	float: right;
	margin-top: -20px;
	font-weight: bold;
}

.star-checkbox {
	font-size: 25px;
	cursor: pointer;
	opacity: 0;
	position: relative;
	z-index: 999;
}

.b:before {
	content: "\2605";
	position: absolute;
	visibility: visible;
	color: #eee;
	margin-top: -9px;
	font-size: 23px;
	top: 35%;
}

.star-checkbox:checked~.b:before {
	content: "\2605";
	position: absolute;
	color: #efd358;
}

.star-checkbox2 {
	font-size: 25px;
	cursor: pointer;
	opacity: 0;
	position: relative;
	z-index: 999;
}

.b2:before {
	content: "\2605";
	position: absolute;
	visibility: visible;
	color: #bdc1d8;
	margin-top: -20px;
	font-size: 26px;
	top: 35%;
	right: 0;
}

.star-checkbox2:checked~.b2:before {
	content: "\2605";
	position: absolute;
	color: #efd358;
}

.disabled-span {
	pointer-events: none;
	cursor: not-allowed;
}

.lightbox-cert {
	.fslightbox-source-inner {
		width: 100%;
	}

	.fslightbox-source {
		width: 100% !important;
		max-width: 840px;
	}

}

.cert-wrapper-2 {
	max-width: 840px;
	width: 100%;
	box-sizing: border-box;
	background: #FFFFFF;
	padding: 30px 20px;
	position: relative;
	background-image: url("/images/option-3.svg");
	background-repeat:no-repeat;
	background-position: center center;
	min-width: 850px;
}
.login-background-image {
	background-image: url("/images/background.png");
}

.cert-wrapper-2-inline {
	position: relative;
	box-sizing: border-box;
	padding: 16px;
}

.cert-logo {
	text-align: center;
	height: 70px;
	max-height: 70px;
}

.cert-name {
	position: relative;
	text-align: center;
	display: flex;
  justify-content: center;
}

.cert-wrapper-2-outline {
	position: relative;
	padding: 5px;
	background-repeat: no-repeat;
	min-height: 580px;
	background-image: url("/images/isolation-mode.svg");
}

.lightbox-cert .fslightbox-source {
	margin-top: 5.5%;
}

.lightbox-cert .fslightbox-source-inner {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 8%;
}

.fslightbox-container.fslightbox-full-dimension.fslightbox-fade-in-strong {
	margin-top: -30px;
}

.fslightbox-slide-btn-container.fslightbox-slide-btn-next-container {
	top: 35%;
}

.fslightbox-slide-btn-container.fslightbox-slide-btn-previous-container {
	top: 35%;
}

.ant-select.form-control.select-user.ant-select-multiple.ant-select-disabled.ant-select-show-search {
	background: #f5f5f5;
}

.ant-select.form-control.ant-select-multiple.ant-select-disabled.ant-select-show-search {
	background: #f5f5f5;
}

.card-plan {
	.card-body {
		padding-left: 0px !important;
		padding-right: 0px !important;
		padding-top: 0px !important;
	}
}

.currently {
	font-size: 13px;
	float: right;
	border: solid 1px;
	padding: 3px;
	border-radius: 5px;
	border-color: #fff;
	font-weight: normal;
	color: #b88fde;
	background: #fff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.MuiTableCell-root,
.MuiTypography-body2 {
	font-family: "Poppins", sans-serif !important;
}

.lightbox-cert {
	.fslightbox-source-inner {
		width: 100%;
	}

	.fslightbox-nav {
		top: 10px;
	}

	.fslightbox-source {
		width: 100% !important;
		max-width: 840px;
	}

	.fslightbox-container {
		min-height: 100vh !important;
		padding-bottom: 10vh;
		padding-top: 10vh;
		.text {
			white-space: normal !important;
			-webkit-line-clamp: 2;
			overflow: hidden;
 			text-overflow: ellipsis;
			-webkit-box-orient: vertical;
			display: -webkit-box;
		}
	}

	.fslightbox-absoluted .fslightbox-full-dimension {
		padding-bottom: 20px;
	}

	.fslightbox-full-dimension {
		max-height: 80vh;
	}

	.fslightboxs {
		width: 100%;
		min-width: 700px;
	} 
}

.home-slider {
	position: relative;
}

.home-slider__button {
	position: absolute;
	z-index: 9;
	cursor: pointer;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	-moz-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	-o-transform: translate(0, -50%);
	transform: translate(0, -50%);
	height: 50px;
	width: 50px;
	display: flex;
	align-items: center;
	border-radius: 50%;
	background: rgba(238, 238, 238, 0.7);
	justify-content: center;
}

.home-slider__button-prev {
	left: 10px;
}

.home-slider__button-next {
	right: 10px;
}

.custom-list-upload .avatar-uploader {
	width: auto;
}

.ant-popover-inner {
	background: #464343;
	border-radius: 5px;

	.ant-popover-inner-content {
		color: #fff;
		font-weight: 100;
	}
}

.ant-popover-placement-top>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-topLeft>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-topRight>.ant-popover-content>.ant-popover-arrow {
	border-right-color: #464343;
	border-bottom-color: #464343;
}

.PhoneInput {
	display: flex !important;
}

.ant-select.form-control.ant-select-disabled {
	background: #f5f5f5;
}

.hover-show-more {
	cursor: pointer;
	display: flex;
	justify-content: center;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;

	&:hover {
		color: #0099FF;
		text-decoration: underline;
	}
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
	object-fit: contain;
}

.ant-picker:hover,
.ant-picker-focused {
	border-color: #9aabff;
}

.ant-select-single .ant-select-selector::after,
.ant-select-single .ant-select-selector .ant-select-selection-item::after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
	margin-top: 4px;
}

.ant-select.form-control.ant-select-disabled {
	background: #f5f5f5;
}

.ant-select-single .ant-select-selector .ant-select-selection-item:after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder:after,
.ant-select-single .ant-select-selector:after {
	margin-top: 4px;
}

.course-introduction {
	border: 1px solid #eee;
	padding: 10px;
	text-align: center;
	cursor: pointer;
	background: #eee;
	font-weight: bold;
}

.course-introduction-has-content {
	border: 1px solid #eee;
	padding: 15px;
	cursor: pointer;
	box-shadow: 1px 1px #eee;
}

.group-image-sort {
	display: flex;
	align-items: center;
}

.input-sesion-name {
	border: none;
	border-bottom: 1px solid;
	border-radius: 0px;
	background: transparent;
}

.open-session {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	cursor: pointer;
	margin-top: 15px;

	.anticon-down,
	.anticon-up {
		&:hover {
			color: #2e40d4;
			border: 1px solid;
			padding: 2px;
			border-radius: 5px;
		}
	}

	.anticon-delete {
		&:hover {
			color: #d71919;
			border: 1px solid;
			padding: 2px;
			border-radius: 5px;
		}
	}

	.open-span {
		margin-left: 2px;

		&:hover {
			color: #2e40d4;
		}
	}

}

.custom-list {
	width: 100%;
	cursor: grab;
	display: contents;
}

.section-upload {

	// .ant-upload.ant-upload-select-picture-card {
	// 	width: 10vw;
	// 	height: 17vh;
	// }
	.ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture-card {
		width: 10vw;
		height: 100%;
	}
}

.content-section {
	height: 60px;
	border: 1px dotted;
	margin-bottom: 10px;
	margin-top: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	&:hover {
		border-color: #2e40d4;
		color: #2e40d4;
	}
}

.list-icon-wapper {
	width: 80px;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 15px !important;
	margin: auto auto;
	background-color: #F4F7FE;
	border-radius: 8px;
}

.list-icon-wapper-customer {
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #F4F7FE;
	border-radius: 8px;
	border: 1px solid #8F9BBA;
}

.group-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-top: 15px;
}

.active-group {
	.list-icon-wapper {
		border: 1px solid #8F9BBA;
	}

	text-align: center;
}

.group-radio-choice {
	display: flex;
	align-items: center;

	.del-choice-img {
		display: flex;
		align-items: flex-start;

		span {
			color: red;
			border: 1px solid #a39898;
			border-radius: 3px;
			padding: 3px;
		}
	}
}

.group-sort-ranking {
	display: flex;
	align-items: center;
	justify-content: center;
}

.group-lesson {
	display: flex;
	justify-content: center;
}

.cursor-pointer {
	cursor: pointer;
}

.item-blank {
	min-width: 150px;
	position: relative;
}

.remove-item-blank {
	position: absolute;
	cursor: pointer;
	right: -10px;
	background-color: #FFFFFF;
	border-radius: 99;
	border-color: #FFFFFF;
}

.view-fill-blank {
	border: 1px solid #b9b6b6;
	padding: 6px 20px;
	margin-left: 20px;
}

.PhoneInput {
	display: flex !important;
}

.popover {
	position: absolute;
	z-index: 2;
	left: 50%;
}

.ant-popover-inner {
	background: #464343;
	border-radius: 5px;

	.ant-popover-inner-content {
		color: #fff;
		font-weight: 100;
	}
}

.ant-popover-placement-top>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-topLeft>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-topRight>.ant-popover-content>.ant-popover-arrow {
	border-right-color: #464343;
	border-bottom-color: #464343
}

.ant-popover-arrow-content {
	--antd-arrow-background-color: #464343;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
	-o-object-fit: contain;
	object-fit: contain
}

.custom-col-9 {
	max-width: calc(100% - 9% - 130px);
	padding-right: 0;
	padding-left: 20px;
}

.custom-col-2 {
	max-width: 104px;
}

.ant-picker:hover,
.ant-picker-focused {
	border-color: #9aabff;
}

.ant-select-single .ant-select-selector::after,
.ant-select-single .ant-select-selector .ant-select-selection-item::after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
	margin-top: 4px;
}

.ant-select.form-control.ant-select-disabled {
	background: #f5f5f5;
}

.ant-select-single .ant-select-selector .ant-select-selection-item:after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder:after,
.ant-select-single .ant-select-selector:after {
	margin-top: 4px;
}

.ant-select-disabled {
	.ant-select-selector {
		.ant-select-selection-item {
			color: #545557;
		}
	}
}